<template>
  <div>
    <page-header title='Formulários'></page-header>
    <v-row justify="end" class="mr-4" v-if="false">
      <v-btn v-if="false" :disabled="$route.name == 'NewMember'" color="white" depressed rounded class="mx-2" @click="$router.push({name: 'NewForm'})"> 
        <v-icon class="mr-1" size="26" color="primary">mdi-plus-circle-outline</v-icon> 
        NOVO FORMULÁRIO
      </v-btn>
    </v-row>
    <v-row v-if="user.role.name != 'student'">
      <v-col cols="12" lg="5">
        <v-card outlined style='border-radius: 18px'>
          <v-card-title class='primary white--text py-3 px-5'>
            Formulários
          </v-card-title>
          <card-loading-transparent v-if="loading"/>
          <v-card-text v-if="!loading" class="px-0">
            <form-list></form-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="7">
        <div v-if="$route.name != 'FormShow'">
          <v-card outlined style='border-radius: 18px;' min-height="100%">
            <v-card-text class="pa-3" >
              <span class="grey--text title pa-3">
              Selecione um formulário para exibir detalhes.
              </span>
            </v-card-text>
          </v-card>
        </div>
        <v-card v-if="$route.name == 'FormShow'" outlined style='border-radius: 18px' class="overflow-hidden">
          <v-card-title class='primary white--text py-4 px-5'>
            <v-app-bar
              class='primary white--text'
              absolute
              elevate-on-scroll
              scroll-target="#scrolling-techniques-7"
            >
              <span class="py-3 px-5">Exibição do Formulário</span>
              <v-spacer></v-spacer>
              <v-btn text color="white" @click="$router.push({name: 'EditForm', params: {id: $route.params.id} })" rounded><v-icon left>mdi-pencil</v-icon> Editar formulário</v-btn>
            </v-app-bar>
          </v-card-title>
          <v-sheet
            id="scrolling-techniques-7"
            class="overflow-y-auto mt-8 px-3"
            max-height="750"
          >
            <v-container>
              <router-view></router-view>
            </v-container>
          </v-sheet>
        </v-card>
        
      </v-col>
    </v-row>
    <unanswered-forms v-if="user.role.name == 'student'" :user_id="user.id"></unanswered-forms>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'FormsIndex',
  components: {
    UnansweredForms: () => import('./unanswered/UnansweredForms.vue'),
    FormList: () => import ('./list/FormList.vue'),
    //NewMember: () => import ('./create/NewMember.vue')
  },
  data() {
    return {
      loading: false,
      selected_member: null,
    }
  },
  methods: {
    getData(){
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 1000);
    },
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    ...mapState({
      user: state=> state.User.user
    })
  },
  mounted () {
    this.getData()
  },
}
</script>

<style scoped>

</style>